<template>
    <div>
        <v-row no-gutters class="pt-3 pl-0 pb-3" dense>
            <div class="d-flex flex-row align-center">
                <v-select
                    :items="years"
                    :style="'max-width: 200px'"
                    clearable
                    dense
                    hide-details="auto"
                    solo
                    v-model="filters.year"
                ></v-select>
                <v-select
                    :items="months"
                    :placeholder="$t('message.all')"
                    :style="'max-width: 200px'"
                    class="ml-3"
                    clearable
                    dense
                    hide-details="auto"
                    item-text="Month.code"
                    item-value="Month.id"
                    v-model="filters.monthFrom"
                    solo
                ></v-select>
                <span class="pl-2">{{ $t('message.to') }}</span>
                <v-select
                    :items="months"
                    :placeholder="$t('message.all')"
                    :style="'max-width: 200px'"
                    class="ml-3"
                    clearable
                    dense
                    hide-details="auto"
                    item-text="Month.code"
                    item-value="Month.id"
                    solo
                    v-model="filters.monthTo"
                ></v-select>
                <v-select
                    :items="paymentStatuses"
                    :placeholder="$t('message.paymentStatus')"
                    :style="'max-width: 250px'"
                    class="ml-3"
                    clearable
                    dense
                    hide-details="auto"
                    solo
                    v-model="filters.paymentStatus"
                ></v-select>
                <v-btn
                    :loading="loading"
                    class="ml-2"
                    @click="loadContractSchedule()"
                >{{ $t('message.fetch') }}
                </v-btn>
            </div>
        </v-row>
        <v-tabs
            class="custom"
            v-model="contractScheduleCurrentTab"
            color="success"
            slider-color="appic-green"
        >
            <v-tab key="ds-ss-my" @click="updateExportParams('ds-ss-my')">{{ $t('message.contractScheduleTabs.dsSsMalaysia') }}</v-tab>
            <v-tab key="ds-ltd" @click="updateExportParams('ds-ltd')">{{ $t('message.contractScheduleTabs.dsLimited') }}</v-tab>
            <v-tab key="ss-id" @click="updateExportParams('ss-id')">{{ $t('message.contractScheduleTabs.ssIndonesia') }}</v-tab>
            <v-tab key="ss-th" @click="updateExportParams('ss-th')">{{ $t('message.contractScheduleTabs.ssThailand') }}</v-tab>
            <v-tab key="wh-my" @click="updateExportParams('wh-my')">{{ $t('message.contractScheduleTabs.whMalaysia') }}</v-tab>
            <v-tab key="wh-id" @click="updateExportParams('wh-id')">{{ $t('message.contractScheduleTabs.whIndonesia') }}</v-tab>
            <v-tab key="wh-th" @click="updateExportParams('wh-th')">{{ $t('message.contractScheduleTabs.whThailand') }}</v-tab>
        </v-tabs>
        <v-tabs-items v-model="contractScheduleCurrentTab">
            <v-tab-item key="ds-ss-my-list">
                <v-card flat>
                    <ContractScheduleList
                        :office="'my'"
                        :sales-type="'ds-ss'"
                        :filters="filters"
                        :load.sync="loadListZero"
                        :reset-export-param="resetExportParams.ds_ss_my"
                        @contract-schedule-loaded="contractScheduleLoaded"
                    ></ContractScheduleList>
                </v-card>
            </v-tab-item>
            <v-tab-item key="ds-ltd-list">
                <v-card flat>
                    <ContractScheduleList
                        :office="'ltd'"
                        :sales-type="'ds'"
                        :filters="filters"
                        :load.sync="loadListOne"
                        :reset-export-param="resetExportParams.ds_ltd"
                        @contract-schedule-loaded="contractScheduleLoaded"
                    ></ContractScheduleList>
                </v-card>
            </v-tab-item>
            <v-tab-item key="ss-id-list">
                <v-card flat>
                    <ContractScheduleList
                        :office="'id'"
                        :sales-type="'ss'"
                        :filters="filters"
                        :load.sync="loadListTwo"
                        :reset-export-param="resetExportParams.ss_id"
                        @contract-schedule-loaded="contractScheduleLoaded"
                    ></ContractScheduleList>
                </v-card>
            </v-tab-item>
            <v-tab-item key="ss-th-list">
                <v-card flat>
                    <ContractScheduleList
                        :office="'th'"
                        :sales-type="'ss'"
                        :filters="filters"
                        :load.sync="loadListThree"
                        :reset-export-param="resetExportParams.ss_th"
                        @contract-schedule-loaded="contractScheduleLoaded"
                    ></ContractScheduleList>
                </v-card>
            </v-tab-item>
            <v-tab-item key="wh-my-list">
                <v-card flat>
                    <ContractScheduleList
                        :office="'my'"
                        :sales-type="'wh'"
                        :filters="filters"
                        :load.sync="loadListFour"
                        :reset-export-param="resetExportParams.wh_my"
                        @contract-schedule-loaded="contractScheduleLoaded"
                    ></ContractScheduleList>
                </v-card>
            </v-tab-item>
            <v-tab-item key="wh-id-list">
                <v-card flat>
                    <ContractScheduleList
                        :office="'id'"
                        :sales-type="'wh'"
                        :filters="filters"
                        :load.sync="loadListFive"
                        :reset-export-param="resetExportParams.wh_id"
                        @contract-schedule-loaded="contractScheduleLoaded"
                    ></ContractScheduleList>
                </v-card>
            </v-tab-item>
            <v-tab-item key="wh-th-list">
                <v-card flat>
                    <ContractScheduleList
                        :office="'th'"
                        :sales-type="'wh'"
                        :filters="filters"
                        :load.sync="loadListSix"
                        :reset-export-param="resetExportParams.wh_th"
                        @contract-schedule-loaded="contractScheduleLoaded"
                    ></ContractScheduleList>
                </v-card>
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
import {mapFields} from "vuex-map-fields";
import {mapGetters} from "vuex";

const ContractScheduleList = () => import("Components/Appic/ContractScheduleList");

export default {
    name: "ContractSchedule",
    components: {ContractScheduleList},
    data(){
        return {
            filters: {
                year: new Date().getFullYear(),
                monthFrom: null,
                monthTo: null,
                paymentStatus: null
            },
            loading: false,
            loadListZero: false,
            loadListOne: false,
            loadListTwo: false,
            loadListThree: false,
            loadListFour: false,
            loadListFive: false,
            loadListSix: false,
            paymentStatuses: [
                {value: 1, text: this.$t('message.buyerPending')},
                {value: 2, text: this.$t('message.supplierPending')},
                {value: 3, text: this.$t('message.buyerFullyPaid')},
                {value: 4, text: this.$t('message.supplierFullyPaid')}
            ],
            resetExportParams: {
                'ds_ss_my': false,
                'ds_ltd': false,
                'ss_id': false,
                'ss_th': false,
                'wh_my': false,
                'wh_id': false,
                'wh_th': false
            }
        }
    },
    computed: {
        ...mapFields('report',{
            exportContractScheduleParams: 'exportContractScheduleParams',
        }),
        ...mapFields('runtime',{
            contractScheduleCurrentTab: 'contractScheduleCurrentTab'
        }),
        ...mapGetters([
            'months'
        ]),
        years(){
            let years = []
            let start = 2020
            let end = new Date().getFullYear();
            let i
            for(i = end; i >= start; i--){
                years.push({value: i, text: i.toString()})
            }
            return years
        }
    },
    methods: {
        contractScheduleLoaded() {
            this.loading = false
            this.loadListZero = false
            this.loadListOne = false
            this.loadListTwo = false
            this.loadListThree = false
            this.loadListFour = false
            this.loadListFive = false
            this.loadListSix = false
        },
        loadContractSchedule() {
            this.loading = true
            switch(this.contractScheduleCurrentTab){
                case 0:
                    this.loadListZero = true
                    break
                case 1:
                    this.loadListOne = true
                    break
                case 2:
                    this.loadListTwo = true
                    break
                case 3:
                    this.loadListThree = true
                    break
                case 4:
                    this.loadListFour = true
                    break
                case 5:
                    this.loadListFive = true
                    break
                case 6:
                    this.loadListSix = true
                    break
            }
        },
        updateExportParams(salesType) {
            switch(salesType){
                case 'ds-ss-my':
                    for (let resetExportParamsKey in this.resetExportParams) {
                        this.resetExportParams[resetExportParamsKey] = false
                    }
                    this.resetExportParams['ds_ss_my'] = true
                    break
                case 'ds-ltd':
                    for (let resetExportParamsKey in this.resetExportParams) {
                        this.resetExportParams[resetExportParamsKey] = false
                    }
                    this.resetExportParams['ds_ltd'] = true
                    break
                case 'ss-id':
                    for (let resetExportParamsKey in this.resetExportParams) {
                        this.resetExportParams[resetExportParamsKey] = false
                    }
                    this.resetExportParams['ss_id'] = true
                    break
                case 'ss-th':
                    for (let resetExportParamsKey in this.resetExportParams) {
                        this.resetExportParams[resetExportParamsKey] = false
                    }
                    this.resetExportParams['ss_th'] = true
                    break
                case 'wh-my':
                    for (let resetExportParamsKey in this.resetExportParams) {
                        this.resetExportParams[resetExportParamsKey] = false
                    }
                    this.resetExportParams['wh_my'] = true
                    break
                case 'wh-id':
                    for (let resetExportParamsKey in this.resetExportParams) {
                        this.resetExportParams[resetExportParamsKey] = false
                    }
                    this.resetExportParams['wh_id'] = true
                    break
                case 'wh-th':
                    for (let resetExportParamsKey in this.resetExportParams) {
                        this.resetExportParams[resetExportParamsKey] = false
                    }
                    this.resetExportParams['wh_th'] = true
                    break
            }
        }
    }
}
</script>
<style>
.custom .v-tab--active {
    background-color: lightgray;
}
.custom .v-tabs-bar {
    height: 35px;
}
</style>